// general colors
$black: #000;

// colors for status
$status-color-green: #2d2;
$status-color-red: #d22;
$status-color-yellow: #aa2;

// variables for status dashboard
$signal-title-size: 1.25em;
$status-signal-small-size: 10px;
$status-signal-small-count: 4;
$status-signal-large-size: ($status-signal-small-size + 2px) * $status-signal-small-count - 2px;
