.content-container {
  padding-top: 80px;
}

.expired {
  border-color: #ea9b9b;
  color: #ea9b9b;
}

.expired-table {
  background: #ea9b9b;
}

.bg-planned {
  background: pink;
}

.bg-running {
  background: #fbc77c;
}

.bg-done {
  background: #c8eac8;
}

.bg-purple {
  background: #a181be;
}

.bg-yellow {
  background: #f1ebcd;
}

.bg-white {
  background: $white;
}

.bg-block {
  padding-bottom: 5rem;
  padding-top: 5rem;
}

.indent {
  padding-left: 2.5rem;
}

.list {
  font-size: 1.5rem;

  li {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.story-list {
  padding: 5rem;
  font-weight: bold;
  list-style: disc;
}

.normal-list {
  list-style: circle;

  li {
    list-style: circle;
  }
}

.big-icon {
  font-size: 10rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.restyle {
  h2, .h2 {
    font-size: 4em;
    padding-left: 2.5rem;
  }

  h3, .h3 {
    font-size: 3em;
    padding-left: 2.5rem;
  }

  h4, .h4 {
    padding-left: 2.5rem;
  }

  strong, b {
    font-weight: 600;
  }

  .lead {
    font-size: 1.4em;
    margin: auto 0;
    max-width: 70%;
    padding-left: 2.5rem;
  }

  .text-block {
    font-size: 1.4em;
    margin: auto 0;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    font-family: 'Hind', sans-serif;
    font-weight: 300;
  }
}

.yellow {
  color: $yellow;
}

.white {
  color: $white;
}

.black {
  color: #101010;
}

.purple {
  color: #a181be;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .restyle {
   .lead {
     max-width: 70%;
   }
  }
  #doro-footer2 h2 {
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .restyle {
    h2, .h2 {
      font-size: 2em;
      padding-left: 1.5rem;
    }

    h3, .h3 {
      font-size: 1.5em;
      padding-left: 1.5rem;
    }

    h4, .h4 {
      font-size: 1em;
      padding-left: 1.5rem;
    }

    .lead {
      font-size: 1em;
      max-width: 80%;
      padding-left: 1.5rem;
    }

    .text-block {
      font-size: 1,3em;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-family: 'Hind', sans-serif;
      font-weight: 300;
    }

    .bg-block {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .img-block {
      min-height: 200px;
    }

    .list {
      font-size: 1rem;

      li {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
      }
    }

    .story-list {
      padding: 1.5rem;
    }


    .big-icon {
      display: none;
    }


  }
  #doro-footer2 h2 {
    line-height: 50px;
  }
}
