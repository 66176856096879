@import 'ppb/variables';
@import 'ppb/navbar';
//@import 'ppb/header';
//@import 'ppb/home';
@import 'ppb/buttons';
@import 'ppb/footer';
@import 'ppb/dashboard';
@import 'ppb/landingoption';
@import 'ppb/gitlab';
@import 'ppb/content';
@import 'ppb/post';
@import 'ppb/signal';
@import 'ppb/flexslider';

html {
  scroll-behavior: smooth;
}

._form {
  font-family: inherit!important;
  font-size: 1.5em!important;
  margin: auto 0!important;
  max-width: 70%!important;
  padding-left: 2.5rem!important;
}