// General signal layout

.signal-dashboard-group {
  .list-group-item {
    padding: .25rem;
  }
}

// General signal styling

.signal-title {
  display: inline-block;
  font-family: 'Asap', sans-serif;
  font-size: $signal-title-size;
  font-weight: 500;
  line-height: normal;
  vertical-align: top;
  width: 200px;
}

//
// Status signal icon
//

.build-status-signal {
  display: inline-block;
  line-height: $status-signal-small-size + 2px;
  width: $status-signal-large-size + 4px;

  .build-status {
    display: inline-block;
    height: $status-signal-small-size;
    margin: 1px 1px 0;
    padding: 0;
    width: $status-signal-small-size;
  }

  :first-child {
    height: $status-signal-large-size;
    width: $status-signal-large-size;
  }
}
