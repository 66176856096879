@import url('https://fonts.googleapis.com/css?family=PT+Sans');
$font-family: 'PT Sans', sans-serif;

$purple: #a181be;
$yellow: #ece242;
$light-yellow: #f1e6cd;

//$green: green;

$theme-colors: (
  primary: $purple,
  secondary: $green,
);


$h1-font-size: 4rem;
h1, h2,
.h1, .h2 {
  text-transform: uppercase;
}

//@import url('https://fonts.googleapis.com/css?family=Carter+One');
@import url('https://fonts.googleapis.com/css?family=Asap:700');
//$headings-font-family: 'Carter One', cursive;
$headings-font-family: 'Norwester', sans-serif;

// Links
//
// Style anchor elements.

$link-color:                $purple;
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

//@font-face {
//  font-family: 'Norwester';
//  src: url('../../fonts/norwester.eot');
//  src: url('../../fonts/norwester.eot?#iefix') format('embedded-opentype'),
//       url('../../fonts/norwester.woff') format('woff'),
//       url('../../fonts/norwester.ttf') format('truetype'),
//       url('../../fonts/norwester.svg#norwesterregular') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}
