.owl-prev, .owl-next {
  font-size: 30px !important;
  line-height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  height: 60px;
  margin: auto 0 !important;
  text-align: center;
  color: white !important;
  border-radius: 1px !important;
  background-color: purple !important;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.owl-prev {
  left: 0;
}

.owl-next {
  right: 0;
}