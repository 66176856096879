/* ----------------------------------------------------------------
[Table of contents]
01. Fonts settings
02. Default styles
03. Sidebar styles
04. Content styles
05. Slider styles
06. Section styles
07. Heading styles
08. Blog & Post styles
09. Project styles
10. Footer styles
11. Contact styles
12. Services styles
13. Navigation styles
14. Team styles
15. Chevron animation
------------------------------------------------------------------- */

/*** 01. Fonts settings (Hind, Oswald, Poppins) ***/

@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700|Oswald:300,400,500,600,700|Poppins:300,400,500,600,700');

/*** 02. Default styles ***/

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 1.75em;
  font-weight: 400;
  color: #777;
}


/*** typography ***/


/* headings */

.doro-hero {
  h1, .h1 {
    color: $yellow !important;
  }

  h2, .h2 {
    color: $light-yellow !important;
  }

  h1, h2,
  .h1, .h2 {
    color: $light-yellow !important;
    text-transform: uppercase;
    font-family: 'Norwester', sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    margin: 0 0 20px 0;
  }

}

h3,
h4,
h5,
h6 {
  color: #101010;
  font-family: 'Norwester', sans-serif;
  font-weight: 500;
  line-height: 1.5em;
  margin: 0 0 20px 0;
}


/* paragraph */

p {
  font-family: 'Hind', sans-serif;
  font-size: 17px;
  line-height: 1.5em;
  margin: 0 0 20px;
  color: black;
}


/* lists */

ul {
  list-style-type: none;
}


/* links */

a {
  color: $purple;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #101010;
  outline: none;
  text-decoration: none !important;
}

a:link {
  text-decoration: none;
}

a:focus {
  outline: none;
}

img {
  width: 100%;
  height: auto;
}

.mb-30 {
  margin-bottom: 30px;
}

.small,
small {
  font-size: 80%;
}

b,
strong {
  color: #101010;
}


/*** form element ***/


/* text field */

button,
input,
optgroup,
select,
textarea {
  font-family: 'Poppins', sans-serif;
}

input[type="password"]:focus,
input[type="email"]:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus,
textarea:focus {
  outline: none;
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="file"],
textarea {
  max-width: 100%;
  margin-bottom: 15px;
  border-color: #ececec;
  padding: 18px 0px 10px;
  height: auto;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-width: 0 0 1px;
  border-style: solid;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 300;
  color: #101010;
  background-image: none;
  border-bottom: 1px solid #ececec;
  border-color: ease-in-out .15s, box-shadow ease-in-out .15s;
}

input:focus,
textarea:focus {
  border-bottom-width: 2px;
  border-color: #101010;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  text-shadow: none;
  padding: 9px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  font-weight: bold;
  color: $yellow;
  -webkit-transition: background-color .15s ease-out;
  transition: background-color .15s ease-out;
  background-color: $purple;
  margin-top: 10px;
}

buttonyellow {
  text-shadow: none;
  padding: 9px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  font-weight: bold;
  color: $purple;
  -webkit-transition: background-color .15s ease-out;
  transition: background-color .15s ease-out;
  background-color: $yellow;
  margin-top: 10px;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover {
  background-color: $yellow;
  color: $white;
}

select {
  padding: 10px;
  border-radius: 5px;
}

table,
th,
tr,
td {
  // border: 1px solid #101010;
}

th,
tr,
td {
  padding: 10px;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline;
}


/* Scrollbar  */

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #101010;
}

::-webkit-scrollbar-thumb:hover {
  background: #101010;
}


/* Selection */

::-webkit-selection {
  color: #777;
  background: rgba(0, 0, 0, 0.1);
}

::-moz-selection {
  color: #777;
  background: rgba(0, 0, 0, 0.1);
}

::selection {
  color: #777;
  background: rgba(0, 0, 0, 0.1);
}


/*** 03. Sidebar styles ***/

#doro-page {
  width: 100%;
  overflow: hidden;
  position: relative;
}

#doro-aside {
  padding: 60px 30px;
  width: 23%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 1001;
  background: #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 1200px) {
  #doro-aside {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  #doro-aside {
    width: 270px;
    -moz-transform: translateX(-270px);
    -webkit-transform: translateX(-270px);
    -ms-transform: translateX(-270px);
    -o-transform: translateX(-270px);
    transform: translateX(-270px);
    padding-top: 4em;
  }
}

#doro-aside #doro-logo {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  line-height: 1em;
  text-transform: uppercase;
  margin-bottom: 60px;
  display: block;
  width: 100%;
}

#doro-aside #doro-logo span {
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  color: #888;
  font-weight: 400;
  letter-spacing: 3px;
}

#doro-logo-footer {
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: block;
  width: 100%;
}

@media screen and (max-width: 768px) {
  #doro-aside #doro-logo {
    margin-bottom: 60px;
  }
}

#doro-aside #doro-logo a {
  display: inline-block;
  text-align: center;
  color: #101010;
  letter-spacing: 3px;
}

#doro-logo-footer a {
  display: inline-block;
  text-align: center;
  color: #101010;
}

#doro-aside #doro-logo a span {
  display: block;
  margin-top: 0px;
  text-align: center;
}

#doro-aside #doro-main-menu ul {
  text-align: left;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 768px) {
  #doro-aside #doro-main-menu ul {
    margin: 0 0 30px 0;
  }
}

#doro-aside #doro-main-menu ul li {
  margin: 15px 0 15px 0;
  padding: 0 0 10px 0;
  list-style: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid #ececec;
}

#doro-aside #doro-main-menu ul li a {
  color: #888;
  text-decoration: none;
  position: relative;
  padding: 0;
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#doro-aside #doro-main-menu ul li a:hover {
  text-decoration: none;
  color: #101010;
}

#doro-aside #doro-main-menu ul li.doro-active a {
  color: #101010;
}

#doro-aside #doro-main-menu ul li.doro-active a:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
  transform: scaleX(1);
}

#doro-aside .doro-footer {
  bottom: 30px;
  right: 0;
  left: 0;
  font-size: 15px;
  text-align: center;
  font-weight: 400;
  color: #777;
  padding: 30px;
}


/* footer */

.doro-footer p {
  margin-bottom: 0;
  color: #999;
}

.doro-footer a {
  color: #101010;
}

@media screen and (max-width: 768px) {
  #doro-aside .doro-footer {
    position: relative;
    padding-top: 90px;
  }
}

#doro-aside .doro-footer span {
  display: block;
}

#doro-aside .doro-footer ul {
  padding: 0 0 5px 0;
  margin: 0 0 5px 0;
}

#doro-aside .doro-footer ul li {
  padding: 0;
  margin: 0;
  display: inline;
  list-style: none;
}

#doro-aside .doro-footer ul li a {
  color: #101010;
  padding: 2px;
}

#doro-aside .doro-footer ul li a:hover,
#doro-aside .doro-footer ul li a:active,
#doro-aside .doro-footer ul li a:focus {
  text-decoration: none;
  outline: none;
  color: #101010;
}


/*** 04. Content styles ***/

#doro-main {
  width: 77%;
  float: right;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

@media screen and (max-width: 1200px) {
  #doro-main {
    width: 70%;
  }
}

@media screen and (max-width: 768px) {
  #doro-main {
    width: 100%;
  }
}

#doro-hero {
  min-height: 560px;
  background: #fff url(src/images/loader.gif) no-repeat center center;
  width: 100%;
  float: left;
  margin-bottom: 0;
  clear: both;
}

#doro-hero .btn {
  font-size: 24px;
}

#doro-hero .btn.btn-primary {
  padding: 15px 30px !important;
}


/*** 05. Slider styles ***/

#doro-hero .flexslider {
  border: none;
  z-index: 1;
  margin-bottom: 0;
}

#doro-hero .flexslider .slides {
  position: relative;
  overflow: hidden;
}

#doro-hero .flexslider .slides li {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  position: relative;
  min-height: 400px;
}

#doro-hero .flexslider .flex-control-nav {
  bottom: 20px;
  z-index: 1000;
  right: 20px;
  float: right;
  width: auto;
}

#doro-hero .flexslider .flex-control-nav li {
  display: block;
  margin-bottom: 6px;
}

#doro-hero .flexslider .flex-control-nav li a {
  background: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 8px;
  height: 8px;
  cursor: pointer;
}

#doro-hero .flexslider .flex-control-nav li a.flex-active {
  cursor: pointer;
  background: #101010;
}

#doro-hero .flexslider .flex-direction-nav {
  display: none;
}

#doro-hero .flexslider .slider-text {
  display: table;
  opacity: 0;
  min-height: 500px;
  padding: 0;
  z-index: 9;
}

#doro-hero .flexslider .slider-text > .slider-text-inner {
  //display: table-cell;
  //vertical-align: middle;
  min-height: 700px;
  padding: 20px;
  position: relative;
  z-index:100;
}

#doro-hero .flexslider .slider-text > .slider-text-inner .desc {
  //position: absolute;
  top: 0px;
  left: 0;
  padding: 20px;
  background: transparent;
  position: relative;
  z-index: 5000;
}

/* video background */
.overlay-video {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;

}

.overlay-video video {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.overlay-color {
  background-color: rgba(155, 125, 181, 0.3);
}

.overlay-color {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}


@media screen and (max-width: 768px) {
  #doro-hero .flexslider .slider-text > .slider-text-inner {

  }
}

#doro-hero .flexslider .slider-text > .slider-text-inner h1 {
  font-size: 80px;
  line-height: 1.5;
  font-family: 'Norwester', sans-serif;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: $yellow;
}

#doro-hero .flexslider .slider-text > .slider-text-inner h2 {
  color: $light-yellow;
}

@media screen and (max-width: 768px) {
  #doro-hero .flexslider .slider-text > .slider-text-inner h1 {
    font-size: 30px;
    margin-top: 50px;
  }
}

#doro-hero .flexslider .slider-text > .slider-text-inner .heading-section {
  font-size: 50px;
}

@media screen and (max-width: 768px) {
  #doro-hero .flexslider .slider-text > .slider-text-inner .heading-section {
    font-size: 25px;
  }
}

#doro-hero .flexslider .slider-text > .slider-text-inner p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  #doro-hero .flexslider .slider-text > .slider-text-inner .btn {
    width: 100%;
  }
}

body.offcanvas {
  overflow-x: hidden;
}

body.offcanvas #doro-aside {
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  width: 270px;
  z-index: 999;
  position: fixed;
}

body.offcanvas #doro-main,
body.offcanvas .doro-nav-toggle {
  top: 0;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
}


/*** 07. Heading styles ***/

.doro-heading {
  color: #101010;
  font-family: 'Norwester', sans-serif;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 30px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.doro-heading span {
  display: block;
}

.doro-about-heading {
  color: #101010;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.doro-post-heading {
  color: #101010;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 24px;
}

.doro-post-heading span {
  display: block;
}

.heading-meta {
  display: block;
  font-family: 'Oswald', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: #777;
  font-weight: 400;
  letter-spacing: 5px;
}

@media screen and (max-width: 768px) {
  .doro-post-heading {
    margin-bottom: 15px;
  }
}


/*** 08. Blog & Post styles ***/

.blog-entry {
  width: 100%;
  float: left;
  background: #fff;
  margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
  .blog-entry {
    margin-bottom: 45px;
  }
}

.blog-entry .blog-img {
  width: 100%;
  float: left;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.blog-entry .blog-img img {
  position: relative;
  max-width: 100%;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.blog-entry .desc h3 {
  font-size: 24px;
  margin-bottom: 20px;
  line-height: 1.5em;
  font-weight: 400;
}

.blog-entry .desc h3 a {
  color: #101010;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.blog-entry .desc span {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  color: #777 !important;
}

.blog-entry .desc span small i {
  color: #777;
}

.blog-entry .desc .lead {
  font-size: 14px;
  color: #101010;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.blog-entry .desc .lead:hover {
  color: #101010;
}

.blog-entry:hover .blog-img img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.doro-post img {
  padding-bottom: 30px;
}


/* Sidebar */
.doro-sidebar-part {
}

.doro-sidebar-block {
  margin-bottom: 60px;
  position: relative;
}

.doro-sidebar-block .doro-sidebar-block-title {
  color: #101010;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

/* Search */

.doro-sidebar-search-form {
  position: relative;
}

.doro-sidebar-search-submit {
  position: absolute;
  right: 0px;
  top: 0px;
  background: none;
  border: 0;
  padding: 12px 20px;
  font-size: 16px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #888;
  background: transparent;
}


/* Categories */

.ul1 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ul1 > li {
  padding: 5px 0;
  border-bottom: 1px solid #f6f6f6;
  line-height: 32px;
}

.ul1 > li a {
  color: #777;
  text-decoration: none;
  display: block;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.ul1 > li a:hover {
  color: #101010;
  text-decoration: none;
}


/* Latest Posts */

.latest {
  margin-bottom: 20px;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 20px;
}

.latest a {
  display: block;
  text-decoration: none;
}

.latest a .txt1 {
  font-weight: 600;
  line-height: 1.5em;
}

.latest a .txt2 {
  color: #777;
  font-size: 13px;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.latest a:hover .txt2 {
  color: #101010;
}

/* Tags, Keywords */

.tags {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.tags li {
  display: inline-block;
  margin: 0 5px 5px 0px;
  float: left;
}

.tags li a {
  display: inline-block;
  background: #fff;
  border: 1px solid #ececec;
  padding: 9px 12px;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  text-decoration: none;
  font-size: 13px;
  color: #777;
  transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.tags li a:hover {
  background: #101010;
  color: #fff;
  border-color: #101010;
}


/* Pagination */

.doro-pagination-wrap {
  padding: 0;
  margin: 0;
  text-align: center;
}

.doro-pagination-wrap li {
  display: inline-block;
  margin: 0 5px;
}

.doro-pagination-wrap li a {
  background: #f6f6f6;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #777;
  font-weight: 400;
  border-radius: 50%;
}

.doro-pagination-wrap li a:hover {
  opacity: 1;
  text-decoration: none;
  box-shadow: 0px 16px 28px 0px rgba(0, 0, 0, 0.20);
}

.doro-pagination-wrap li a.active {
  background-color: #101010;
  border: 1px solid #101010;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .doro-pagination-wrap {
    padding: 0 0 60px 0;
    margin: 0;
    text-align: center;
  }
}


/*Post Comment */
.comments-area {
  padding: 30px 0;
}

.comments-area .comments-title {
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5em;
  font-weight: 600;
}

ol.comment-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

ol.comment-list li.comment {
  position: relative;
  padding: 0;
}

ol.comment-list li.comment .comment-body {
  position: relative;
  padding: 20px 30px 20px 90px;
  margin-left: 40px;
  color: #101010;
  position: relative;
}

ol.comment-list li.comment .comment-author {
  display: block;
  margin-bottom: 0px;
}

ol.comment-list li.comment .comment-author .avatar {
  position: absolute;
  top: 20px;
  left: -40px;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  background-color: #fff;
}

ol.comment-list li.comment .comment-author .name {
  display: inline-block;
  color: #101010;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 600;
  margin-bottom: 0;
}

ol.comment-list li.comment .comment-author .says {
  display: none;
  color: #888;
  font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
  color: #888;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 300;
}

ol.comment-list li.comment .comment-meta a {
  color: #101010;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  vertical-align: top;
}

ol.comment-list li.comment p {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  margin: 0 0 20px;
  color: #888;
}

ol.comment-list li.comment .reply a {
  position: absolute;
  top: 50px;
  right: 30px;
  margin-top: -5px;
  font-size: 13px;
  color: #888;
}

ol.comment-list li .children {
  list-style: none;
  margin-left: 80px;
}

ol.comment-list li .children li {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }

  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 30px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    left: -35px;
    height: 70px;
    width: 70px;
  }

  ol.comment-list li .children {
    margin-left: 20px;
  }

  ol.comment-list li.comment .reply a {
    position: static;
  }
}

@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 26px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    left: -15px;
    top: 12px;
    width: 60px;
    height: 60px;
  }

  ol.comment-list li.comment .comment-body {
    padding-left: 55px;
  }
}


/*** 09. Project styles ***/

.project {
  position: relative;
  margin-bottom: 30px;
}

.project .desc {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  background: rgba(155, 125, 181, 0.94);
  opacity: 0;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s
}

.project .desc .con {
  padding: 30px;
  display: block;
  position: relative;
}

.project .desc .con .icon {
  position: absolute;
  bottom: 30px;
  left: 30px;
  right: 30px
}

.project .desc .con .icon span {
  display: inline;
  padding-right: 5px
}

.project .desc .con .icon span a {
  color: #ccc;
  padding: 5px;
  background: #333;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px
}

.project .desc .con .icon i {
  font-size: 16px;
  color: #ccc
}

.project .desc h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transition: -webkit-transform .3s, opacity .3s;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -o-transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  margin-bottom: 15px
}

.project .desc h3 a {
  color: #fff
}

.project .desc span {
  display: block;
  color: #ccc;
  font-size: 13px;
  -webkit-transition: -webkit-transform .3s, opacity .3s;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  -o-transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0)
}

.project .desc span a {
  color: #101010
}

.project:hover .desc {
  opacity: 1
}

.project:hover .desc h3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.project:hover .desc span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.project-desc h2 {
  color: #101010;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
}

.project-desc span {
  display: block;
  color: #666;
  font-size: 13px;
  margin-bottom: 15px
}

.project-desc span a {
  color: #666
}

.project-desc .icon span {
  display: inline;
  padding-right: 5px
}


/*** 06. Section styles ***/

.doro-blog,
.doro-post,
.doro-projects,
.doro-recent-projects,
.doro-team,
.doro-about,
.doro-services,
.doro-contact {
  padding-top: 60px;
  padding-bottom: 60px;
  clear: both;
  width: 100%;
  display: block;
}

@media screen and (max-width: 768px) {
  .doro-post,
  .doro-projects,
  .doro-blog,
  .doro-about,
  .doro-contact {
    padding-top: 90px;
    padding-bottom: 60px;
  }
  .doro-team {
    padding-top: 0px;
  }
  .heading-meta {
    margin-bottom: 0px;
  }
  .doro-services {
    padding-top: 90px;
    padding-bottom: 0px;
  }
  .doro-recent-projects {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

.doro-recent-project {
  h2 {
    color: $white;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .desc:hover {
    h2 {
      opacity: 0;
    }
  }
}


/*** 10. Footer styles ***/

#doro-footer2 {
  padding: 120px;
  background: $light-yellow;
}

#doro-footer2 h2 {
  margin: 0 0 20px 0;
  color: #101010;
  font-size: 40px;
  line-height: 30px;
  letter-spacing: 3px;
}

@media screen and (max-width: 768px) {
  #doro-footer2 {
    padding: 100px 30px 80px 30px;
    background: #f6f6f6;
    text-align: center;
  }
}

.doro-lead {
  font-size: 13px;
  line-height: 2em;
}


/* social 1 */

.doro-social {
  padding: 0;
  margin: 0;
  text-align: center;
}

.doro-social li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.doro-social li a {
  font-size: 22px;
  color: #101010;
  padding: 10px;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}

@media screen and (max-width: 768px) {
  .doro-social li a {
    padding: 10px 8px;
  }
}

.doro-social li a:hover {
  color: #101010;
}

.doro-social li a:hover,
.doro-social li a:active,
.doro-social li a:focus {
  outline: none;
  text-decoration: none;
  color: #101010;
}


/* social 2 */

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
  float: right;
  margin-bottom: 20px;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
}

.social-network li a {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  ul.social-network {
    display: inherit;
    text-align: center;
    float: none;
    margin-bottom: 0;
  }
}


/*** 11. Contact styles ***/

.map-section {
  overflow: hidden;
  margin-bottom: 60px;
}

#contactMap {
  width: 100%;
  height: 550px;
}

.doro-more-contact {
  background: #fafafa;
}


/* Form */

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #e5e5e5;
  height: 54px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  padding: 0;
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  font-weight: 300;
}

.form-control:active,
.form-control:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #101010;
  border-bottom: 2px solid #101010;
}

.btn-contact {
  font-family: 'Hind', sans-serif;
  font-size: 14px;
  line-height: 1.75;
  font-weight: 400;
  border-style: solid;
  color: #fff;
  letter-spacing: 1px;
  border-width: 0;
  -webkit-transition: background-color .15s ease-out;
  transition: background-color .15s ease-out;
  background-color: #101010;
  margin-top: 10px;
  border-radius: 0;
}

#message {
  height: 130px;
}


/*** 12. Services styles ***/

.doro-feature {
  text-align: left;
  width: 100%;
  float: left;
  margin-bottom: 30px;
  position: relative;
}

.doro-feature .doro-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: table;
  text-align: center;
  background: transparent;
}

@media screen and (max-width: 1200px) {
}

.doro-feature .doro-text {
  padding-left: 70px;
  width: 100%;
}

.doro-feature .doro-text h2,
.doro-feature .doro-text h3 {
  margin: 0;
  padding: 0;
}

.doro-feature .doro-text h3 {
  font-weight: 600;
  margin-bottom: 20px;
  color: #101010;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.doro-feature.doro-feature-sm .doro-text {
  margin-top: 6px;
}

.doro-feature.doro-feature-sm .doro-icon i {
  color: #101010;
  font-size: 40px;
}

@media screen and (max-width: 1200px) {
  .doro-feature.doro-feature-sm .doro-icon i {
    font-size: 28px;
  }
}


/*** 13. Navigation styles ***/

.doro-nav-toggle {
  cursor: pointer;
  text-decoration: none;
}

.doro-nav-toggle.active i::before,
.doro-nav-toggle.active i::after {
  background: #fff;
}

.doro-nav-toggle.dark.active i::before,
.doro-nav-toggle.dark.active i::after {
  background: #101010;
}

.doro-nav-toggle:hover,
.doro-nav-toggle:focus,
.doro-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}

.doro-nav-toggle i {
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  width: 20px;
  height: 1px;
  color: #fff;
  font: bold 14px/.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #fff;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
  top: -2px;
}

.doro-nav-toggle i::before,
.doro-nav-toggle i::after {
  content: '';
  width: 20px;
  height: 1px;
  background: #fff;
  position: absolute;
  left: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.doro-nav-toggle.dark i {
  position: relative;
  color: #101010;
  background: #101010;
  -webkit-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}

.doro-nav-toggle.dark i::before,
.doro-nav-toggle.dark i::after {
  background: #101010;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.doro-nav-toggle i::before {
  top: -7px;
}

.doro-nav-toggle i::after {
  bottom: -7px;
}

.doro-nav-toggle:hover i::before {
  top: -10px;
}

.doro-nav-toggle:hover i::after {
  bottom: -10px;
}

.doro-nav-toggle.active i {
  background: transparent;
}

.doro-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.doro-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
}

.doro-nav-toggle {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9999;
  cursor: pointer;
  opacity: 1;
  visibility: hidden;
  padding: 12px 15px;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  background: #101010;
  margin: 15px 30px;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .doro-nav-toggle {
    opacity: 1;
    visibility: visible;
  }
}

.js .animate-box {
  opacity: 0;
}


/* sticky style */

#sticky_item {
  overflow: hidden;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

#sticky_item.is_stuck {
  margin-top: 10px;
}

@media screen and (max-width: 992px) {
  .sticky-parent {
    clear: both;
    height: inherit !important;
    float: left;
    padding-top: 30px;
  }
}


/* 14. Team styles */

.team {
  position: relative;
  margin-bottom: 30px;
}

.team .desc {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  color: $black;
  background: rgba(#f1ebcd, 0.77);
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.team .desc .con {
  padding: 30px;
  display: block;
  position: relative;
}

.team .desc .con .icon {
  position: absolute;
}

.team .desc .con .icon span {
  display: inline;
  padding-right: 5px;
}

.team .desc .con .icon i {
  font-size: 16px;

}

.team .desc h3 {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  margin-bottom: 15px;
}

.team .desc span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  -o-transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translate3d(0, 15px, 0);
  transform: translate3d(0, 15px, 0);
  margin-bottom: 15px;
}


.team:hover .desc {
  opacity: 1;
}

.team:hover .desc h3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.team:hover .desc span {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.team-desc h2 {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.team-desc span {
  display: block;
  color: #666;
  font-size: 13px;
  margin-bottom: 15px;
}

.team-desc span a {
  color: #666;
}

.team-desc .icon span {
  display: inline;
  padding-right: 5px;
}

@media screen and (max-width: 768px) {
  .team .desc {
    opacity: 1;
    background: transparent;
  }
  .team .desc h3 {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    color: #101010;
    background-color: rgba(255, 255, 255, 0.2)
  }
  .team .desc span {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }
}

.container-fluid {
  padding-right: 30px;
  padding-left: 30px;
}


.font-35px {
  font-size: 35px;
}

.font-14px {
  font-size: 14px;
}

.gray-icon {
  color: #101010;
  background: transparent;
  padding: 6px;
  border-radius: 50%;
}

.black-icon {
  color: #777;
}

hr {
  margin-top: 0px;
  margin-bottom: -20px;
  border: 0;
}

.img-logo {
  max-height: 120px;
  max-width: 120px;
}

/*** 15 Chevron animation ***/

$base: 0.6rem;


@media screen and (max-width: 768px) {
  $base: 0.3rem;
}
.container-chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: white;
  position:absolute;
  z-index:50;
  top: 0;
    a {
        color: white;
        font-size: 1.4rem;
        padding: 10px;

    }
  @media screen and (max-width: 768px) {
  display:none;
}
}

.chevron-base {
  position: absolute;
  bottom: 0;
  padding-bottom: 90px;
}

.chevrons {
  text-align: center;
  position: absolute;
  height: 100px;
  width: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.chevron {
  position: absolute;
  width: $base * 3.5;
  height: $base * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
  right: 0;
  left: 0;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: white;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY($base * 3.8);
  }
  66.6% {
    opacity: 1;
    transform: translateY($base * 5.2);
  }
  100% {
    opacity: 0;
    transform: translateY($base * 8) scale(0.5);
  }
}
