.build-status {
  border: 1px solid $black;
  border-radius: 2px;
  padding: 2px;
}

.build-success {
  background-color: $status-color-green;
}

.build-pending {
  background-color: $status-color-yellow;
}

.build-failure {
  background-color: $status-color-red;
}
