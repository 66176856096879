.section-title h2 {
  color: purple;
}

.navbar {
  border: none;
  transition: 0.4s;
  &-brand {
    font-size: 2.25rem;
    font-weight: 900;
    color: #4b4b4b;
  }
  &.fixed-color {
    background-color: $black;
  }
  &.fixed-height {
    height: 80px;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
  &.open {
    background: $black!important;
  }
  &.unfixed-color {
    background-color: transparent;
    a {
      color: $white;
      &:hover {
        color: rgba($black, 0.2);
      }
      &.active {
        color: $purple;
      }
    }
  }
  a {
    transition: all 0.4s;
    color: $white;
    &:hover {
      color: rgba($black, 0.2);
    }
    &.active {
      color: $purple;
    }
  }
  &.unfixed-height {
    height: 150px;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
  img {
    max-height: 70px;
    width: auto;
  }
}

.navbar-brand,
.navbar-text {
  a {
    color: $white;
  }
}

#navbarResponsive {
  a {
    font-size: 20px;
  }
}

.nav-sidebar {
  margin-top:30vh;
  padding-left: 20px;
  z-index: 2;
  @include media-breakpoint-down(md) {
    display: none;
  }
  a {
  display:block;
  color: $white;
  background-color: rgba($purple, 0.8);
  padding: 5px 20px!important;
  margin: 10px;
    &:hover, &:focus {
      background-color: rgba(darken($purple, 40), 0.7);
      text-decoration: none;
    }
    &.active {
      background-color: rgba(darken($purple, 40), 0.7);
    }
  }

}